import styled from 'styled-components';

import {colors} from '../../constants/Colors';

export const HeaderSection = styled.header`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    padding: 16px;
    z-index: 2000;
    background-color: ${(props) => props.color || colors.white};
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 990px) {
        background-color: ${(props)=>props.fixWhiteBackgroundOnMobile && props.color ? 'white' : props.color};
    }
`;

export const Logo = styled.img`
    height: auto;
    ${props => props.enableLink ? 'cursor:pointer;': ''}
`;

export const LogoPoweredby = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoDescription = styled.span`
    font-style: italic;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.colorStyle === 'default' ? colors.darkGray : colors.white};
    margin-right: 16px;
`;

export const ExternalLink = styled.a`
    text-decoration: none;

    &:visited {
        text-decoration: none;
    }

    @media screen and (min-width: 780px) {
        ${props=>props.showBigLogoOnlyMobile ? 'display: none' : ''};                       
    }

`;