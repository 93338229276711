import * as Yup from 'yup';

export const initialValuesMock = {    
    "cnpj": "00000000000000", 
    "question_01":100,
    "question_02":50,
    "question_03":50,
    "question_04":50,
    "question_05":10,
    "question_06":50,
    "question_12":5,
    "question_13":5,
    "question_07":"Sim",
    "question_08":"Sim",
    "question_09":"Sim",
    "question_10":"Sim",
    "question_11":"Sim",
    "question_39":"Sim",
    "question_14":"Talvez",
    "question_15":5,
    "question_16":"Sim, e é BR Mania",
    "question_17":"Sim",
    "question_18":"Sim, e é Lubrax+",
    "question_19":true,
    "question_20":true,
    "question_21":true,
    "question_22":true,
    "question_23":true,
    "question_24":true,
    "question_25":true,
    "question_26":true,
    "question_27":true,
    "question_28":true,
    "question_29":true,
    "question_30":true,
    "question_31":true,
    "question_32":true,
    "question_33":true,
    "question_34":true,
    "question_35":true,
    "question_36":true,
    "question_37":true,
    "question_38":true,
    "question_44":true,
    "question_40":true,
    "question_41":true,
    "question_42":true,
    "question_43":true,
    "question_45":true,
};

export const initialValues = {    
    "cnpj": "00000000000000", 
    "question_01":100,
    "question_02":50,
    "question_03":50,
    "question_04":50,
    "question_05":10,
    "question_06":50,
    "question_12":"",
    "question_13":"",
    "question_07":"",
    "question_08":"",
    "question_09":"",
    "question_10":"",
    "question_11":"",
    "question_39":"",
    "question_14":"--",
    "question_15":"",
    "question_16":"--",
    "question_17":"",
    "question_18":"--",
    "question_19":false,
    "question_20":false,
    "question_21":false,
    "question_22":false,
    "question_23":false,
    "question_24":false,
    "question_25":false,
    "question_26":false,
    "question_27":false,
    "question_28":false,
    "question_29":false,
    "question_30":false,
    "question_31":false,
    "question_32":false,
    "question_33":false,
    "question_34":false,
    "question_35":false,
    "question_36":false,
    "question_37":false,
    "question_38":false,
    "question_44":false,
    "question_40":false,
    "question_41":false,
    "question_42":false,
    "question_43":false,
    "question_45":false,
};

export const validationSchema = Yup.object().shape({
    "cnpj": Yup.string().required('Campo obrigatório'), 
    "question_01": Yup.number().required('Campo obrigatório'),
    "question_02":Yup.number().required('Campo obrigatório'),
    "question_03":Yup.number().required('Campo obrigatório'),
    "question_04":Yup.number().required('Campo obrigatório'),
    "question_05":Yup.number().required('Campo obrigatório'),
    "question_06":Yup.number().required('Campo obrigatório'),
    "question_12":Yup.number().required('Campo numérico obrigatório.'),
    "question_13":Yup.number().required('Campo numérico obrigatório'),
    "question_07":Yup.string().required('Campo obrigatório'), 
    "question_08":Yup.string().required('Campo obrigatório'), 
    "question_09":Yup.string().required('Campo obrigatório'), 
    "question_10":Yup.string().required('Campo obrigatório'), 
    "question_11":Yup.string().required('Campo obrigatório'), 
    "question_39":Yup.string().required('Campo obrigatório'), 
    "question_14":Yup.string() 
        .oneOf(['Sim', 'Não', 'Talvez',],'Escolha uma opção válida')
        .required('Campo obrigatório'), 
    "question_15":Yup.number().required('Campo numérico obrigatório'),
    "question_16":Yup.string() 
        .oneOf(["Sim, e é BR Mania", "Sim, mas não é BR Mania", "Não",],'Escolha uma opção válida')
        .required('Campo obrigatório'),  
    "question_17":Yup.string().required('Campo obrigatório'), 
    "question_18":Yup.string() 
        .oneOf(["Sim, e é Lubrax+", "Sim, mas não é Lubrax+", "Não",],'Escolha uma opção válida')
        .required('Campo obrigatório'), 
    "question_19":Yup.boolean(), 
    "question_20":Yup.boolean(), 
    "question_21":Yup.boolean(), 
    "question_22":Yup.boolean(), 
    "question_23":Yup.boolean(), 
    "question_24":Yup.boolean(), 
    "question_25":Yup.boolean(), 
    "question_26":Yup.boolean(),
    "question_27":Yup.boolean(),
    "question_28":Yup.boolean(),
    "question_29":Yup.boolean(),
    "question_30":Yup.boolean(),
    "question_31":Yup.boolean(),
    "question_32":Yup.boolean(),
    "question_33":Yup.boolean(),
    "question_34":Yup.boolean(),
    "question_35":Yup.boolean(),
    "question_36":Yup.boolean(),
    "question_37":Yup.boolean(),
    "question_38":Yup.boolean(),
    "question_44":Yup.boolean(),
    "question_40":Yup.boolean(),
    "question_41":Yup.boolean(),
    "question_42":Yup.boolean(),
    "question_43":Yup.boolean(),
    "question_45":Yup.boolean(),
  });