

export const colors = {
    mainGreen : "#14B352",
    iconGreen: "#14B35280",
    white : "#FFFFFF",
    darkGray: "#A5A5A5",
    black: "#000000",
    darkWhite: "#FFFFFFB3",

    SUCCESS: '#4CAF50',
    WARNING: '#ff9800',
    ERROR: '#f44336',
    INFO: '#2196F3'
}   