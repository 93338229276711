import React from 'react'
import { colors } from '../../constants/Colors';

import {
    AlertContainer,
    CloseIcon
} from './styles';

export default function Alert({
    close, 
    type, 
    message, 
    duration=3, 
    fadeOut = false,
    closeEnable = false 
}) {
    const bgColor = colors[type];
    return (     
        <AlertContainer bgColor={bgColor} duration={duration} fadeOut={fadeOut}>
            {closeEnable ? <CloseIcon onClick={close}>&times;</CloseIcon> : null}
            {message}
        </AlertContainer>        
    )
}
