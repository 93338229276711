import React from 'react';
import {
    Field, ErrorMessage
} from 'formik';
import {
    InputContainer,
    Label,
    LeftButton,
    RightButton,
    ComposedInput,
    InputValue
} from './styles';


const MinusPlusInput = ({fieldName, label, min = 0, max = 0}) => {

    const validate = (currentValue) => {
        if (min <= currentValue && currentValue <= max){
            return true;
        }
        return false;
    }
    
    return (
        <Field name={fieldName} id={fieldName} type="number">

       

        {({ field: { value }, form: { setFieldValue } }) => (
            <InputContainer>
            <Label htmlFor={fieldName} >
                {label}
            </Label>
            <ComposedInput>
                <LeftButton type="button" value="-" onClick={() => {
                    if(validate(value - 1)) {
                        setFieldValue(fieldName, value - 1);
                    }
                }}/>
                <InputValue>{value}</InputValue>
                <RightButton type="button" value="+" onClick={() => {
                    if(validate(value + 1)) {
                        setFieldValue(fieldName, value + 1);
                    }                    
                }}/>
            </ComposedInput>
            <ErrorMessage name={fieldName} />
            </InputContainer>
        )}
        </Field>
  );
      };

  export default MinusPlusInput;