import styled from 'styled-components';



export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.label`
   color: #212121;
   font-size: 16px; 
`;

export const ComposedInput = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    position: relative;
    width: 120px;
`;

export const Input = styled.input`
    background-color: white;
    min-width: 38px;
    width: auto;
    transition: all 300ms ease;
    border: 4px solid #eeeeee;    
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    height: 38px;
    outline: none;  
`;

export const LeftButton = styled(Input)`    
    border-radius: 8px 0px 0px 8px;
`;

export const RightButton = styled(Input)`
    border-radius: 0px 8px 8px 0px;
`;

export const InputValue = styled.span`
    background-color: white;
    min-width: 45px;
    width: auto;
    transition: all 300ms ease;
    border-top: 4px solid #eeeeee;
    border-bottom: 4px solid #eeeeee;        
    font-size: 16px;
    height: 38px;
    display: flex;
    align-items: center; 
    justify-content: center; 
`;