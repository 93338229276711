export const POST = 'POST';
export const GET = 'GET';
export const GET_COOKIE = 'GET_COOKIE';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const LOGIN = 'LOGIN';
export const DOWNLOAD = 'DOWNLOAD';

export const ERROR_CONNECTION = 'Um erro ocorreu na comunicação com servidor.';
export const ERROR_ACCESS_DANIED = 'Sem permissão para acesso.';
export const ERROR_NOT_FOUND = 'Nenhum retorno encontrado.';
export const ERROR_INTERNAL = 'Houve um erro interno no servidor.';