import React from 'react'

import {
    Counter,
    Digit,

} from './styles';

const renderListItems = () => ( <>
        <li key={0}>0</li>
        <li key={1}>1</li>
        <li key={2}>2</li>
        <li key={3}>3</li>
        <li key={4}>4</li>
        <li key={5}>5</li>
        <li key={6}>6</li>
        <li key={7}>7</li>
        <li key={8}>8</li>
        <li key={9}>9</li>
    </>
);

let padToFive = number => number <= 99999 ? `0000${number}`.slice(-5) : number;

const RollingNumbers = ({ number = 123 }) => {

const formmatedNumber = padToFive(number);

 return (    
        <Counter >
            {[...formmatedNumber].map((digit, index)=>(
                <Digit key={index} digit={digit}>
                    {renderListItems()}
                </Digit>
            ))}           
        </Counter>
 )
}
export default RollingNumbers;


