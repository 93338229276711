import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { GlobalStyle } from './globalStyles';
import Login from './pages/Login';
import Home from './pages/Home';
import Form from './pages/Form';
import Address from './pages/Address';

import { isAuthenticated } from './api/services';

export const ThemeContext = React.createContext();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={ props => isAuthenticated() ? (
      <Component {...props} />
    ): (
      <Redirect to={{pathname: '/login', state: { from: props.location }}} />
    )}
    />
);

function App() {

  const [bodyColor, setBodyColor] = useState('white');


  return (
    <ThemeContext.Provider value={{setBodyColor}}>
      <BrowserRouter>
      <GlobalStyle bodyColor={bodyColor}/>
      <Switch>
        <Route path="/login" exact={true} component={Login} />
        <PrivateRoute path="/" exact={true} component={Home} />      
        <PrivateRoute path="/form" exact={true} component={Form} />
        <Route path="/address" exact={true} component={Address} />
        <Route path="*" component={() => <h1>Page not found</h1>} />      
        
      </Switch>
      </BrowserRouter>
    </ThemeContext.Provider>    
  

    
  );
}

export default App;
