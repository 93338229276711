import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeContext } from '../../App';
import {
    MapContainer, 
    TileLayer, 
    Marker, 
    useMap, 
    Popup,
    ZoomControl
} from 'react-leaflet';
import { FaPencilAlt } from 'react-icons/fa';
import {
    Header, 
    TextInput, 
    Button, 
    CollapsedButton, 
    Alert
} from '../../components/index';

import BombaImage from '../../images/bomba-2-cut.png';
import DownloadIcon from '../../assets/svg/Download.svg';
import { SUCCESS, WARNING, ERROR } from '../../constants/Constants';
import { 
    getAddress, 
    getSimulationList, 
    getSimulation,
    saveAddress,
    downloadSimulations 
} from '../../api/services';
import { formatBodyAddress } from '../../utils/functions/createBody';
import { isValidCNPJ, removeFormat } from '../../utils/functions/string';
import { colors } from '../../constants/Colors';
import { estados } from '../../constants/UsefulData';

import {
    Container, 
    ColumnsContainer,      
    LeftImage, 
    HelloText, 
    WelcomeText, 
    GetStartedText,
    ButtonsContainer, 
    DownloadImage,
    DownloadText,
    PostoTitle,
    AddressContainer,
    AddressTitle,
    AddressLine,
    AddressInputContainer,
    AddressLabel,
    AddressInput,
    Select,
    FormAddress,
    ButtonDownload,
    ColumnForm,
    ColumnImage,
    ColumnAddress,
    MapPageContainer,
    HoverIcon,
    FadeInTransparency
    
} from './styles';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { saveUserAgent } from '../../api/services';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,    
    iconAnchor: [17, 46]
});

L.Marker.prototype.options.icon = DefaultIcon;


const Home = () => {
    const [cnpj, setCnpj] = useState('');
    const [simulationList, setSimulationList] = useState([]);
    const [showMapAddress, setShowMapAddress] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(null);
    const [editAddress, setEditAddress] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [loadedFormValues, setLoadedFormValues] = useState(null);
    const [displayAlert, setDisplayAlert] = useState(null);
    const { setBodyColor } = useContext(ThemeContext);
    
    const handleChange = (event) => {
        event.persist();
        const value = event.target.value;
        setCurrentAddress({
            ...currentAddress,
            [event.target.name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();        
        if( cnpj 
            && currentAddress 
            && currentAddress.street_address
            && currentAddress.state
            && currentAddress.city  ) {
                try {
                    const addressBody = formatBodyAddress(cnpj, currentAddress);                    
                    const result = await saveAddress(addressBody);
                    if(result.status === 1) {
                        setEditAddress(false);
                    }                    
                    handleAlert({
                        type: SUCCESS,
                        message: 'Endereço salvo.',
                        closeEnable:true
                    }, 7000);                    
                } catch (e) {                    
                    handleAlert({
                        type: WARNING,
                        message: e.message,
                        closeEnable:true
                    }, 7000);                     
                }                
            }        
    };

    const handleDownloadSimulations = () => {
        try {
            downloadSimulations(`${getDate()}_BR_simulacoes.xlsx`);
        } catch (e) {
            handleAlert({
                type: ERROR,
                message: e.message,
                closeEnable:true
            }, 7000); 
        }
    }

    const loadAddress = async () => {
        try{
            saveUserAgent(window.__USER_AGENT__);
            //if(isValidCNPJ(cnpj)) {
            if(cnpj) {
                const result = await getAddress({cnpj:removeFormat(cnpj)});                
                if(result && result.company_name && result.lat && result.lon ) {
                    setCurrentAddress(result);
                    setShowMapAddress(true);
                    setBodyColor('white');
                } else {
                    handleAlert({
                        type: ERROR,
                        message: "Não foram encontradas informações para esse CNPJ.",
                        closeEnable: true
                    }, 7000);                   
                }               
            } else {
                handleAlert({
                    type:WARNING, 
                    message:'Informe um CNPJ.',                    
                    closeEnable: true,
                }, 7000);
            }           
        }catch(e){            
            handleAlert({
                type: WARNING,
                message: e.message,
                closeEnable:true
            }, 7000);
        }
    }

    const nextFunction = async () => {                    
        loadAddress();                
    }

    const handleAlert = (alertObj, duration) => { 
        setDisplayAlert(alertObj);
        setTimeout(() => {
            setDisplayAlert(null);            
          }, duration);
    };

    const getDate = () => {
        const date = new Date();
        return date.toISOString().split('T')[0];
    }

    const getSimulationFunction = async (simulationName) =>{
        try{
            const result = await getSimulation({simulation_name: simulationName});
            setLoadedFormValues(result);
            setRedirectToForm(true);            
        }catch(e){            
            handleAlert({
                type: WARNING,
                message: e.message,
                closeEnable:true
            }, 7000);            
        }
    }

    useEffect(() => {
        if(showMapAddress  && cnpj.length === 18 && isValidCNPJ(cnpj)) {            
            loadAddress();
        }
    },[cnpj]);

    useEffect(() => {
        const getlist = async () =>{
            try {
                const result = await getSimulationList();
                setSimulationList(result.simulations_list || [])
            }catch(e){
                setSimulationList([]);
                handleAlert({
                    type: WARNING,
                    message: e.message,
                    closeEnable:true
                }, 7000);                                
            }            
        }
        getlist();
        if(!showMapAddress) {
            setBodyColor(colors.mainGreen);
        }
        return () => setBodyColor("white");       
    }
    ,[])

    const ChangeView = ({ center, zoom }) => {
        const map = useMap();
        map.setView(center, zoom);
        return null;
      }

    if(redirectToForm) {
        return (<Redirect to={{
            pathname: "/form",          
            formValues: loadedFormValues,
            cnpj:cnpj,
            currentAddress:currentAddress
          }} />)
    }
    return (

        
        <Container 
            color={showMapAddress ? 'white' : colors.mainGreen} 
            paddingBottom={showMapAddress? false: '100px'}
        >
            
            {displayAlert ? 
                <Alert 
                    close={()=>setDisplayAlert(null)}
                    type={displayAlert.type} 
                    message={displayAlert.message}
                    closeEnable={displayAlert.closeEnable}  
                /> : null}

            <AnimatePresence exitBeforeEnter>
            { !showMapAddress && (                
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                <Header 
                    enableLink={false} 
                    rightElementsStyle={showMapAddress ? 'default' :'white'} 
                    color= {showMapAddress ? 'white' :'transparent' }
                    clickAction={()=> {
                        setShowMapAddress(false)
                        setBodyColor(colors.mainGreen)
                    }}                                    
            />
                <ColumnsContainer >
                <ColumnImage color={colors.mainGreen}>
                    <LeftImage src={BombaImage} alt={'texto'} />
                </ColumnImage>
                <ColumnForm color={colors.mainGreen} >
                <FadeIn>
                    <HelloText>Olá!</HelloText>
                    <WelcomeText>Bem-vindo ao simulador de potencial de volume da Vibra Energia</WelcomeText>
                
                    <GetStartedText>Para começar a simular, informe:</GetStartedText>
                    <TextInput 
                        inputType={'cnpj'} 
                        placeholder={'CNPJ a ser simulado'} 
                        value={cnpj} 
                        setValue={setCnpj}
                        placeholderTextColor={colors.darkWhite}
                    />
                    <ButtonsContainer>
                        <CollapsedButton 
                            list={simulationList} 
                            text={'Carregar Simulação'} 
                            colorScheme={'green-background-white-text'} 
                            width={'60%'} 
                            hasRightIcon 
                            onPressFunction = {(val) => getSimulationFunction(val)}/>
                        <Button 
                            text={'Avançar'} 
                            colorScheme={'white-background-green-text'} 
                            width={'38%'} 
                            onPress={nextFunction}
                            // disabled={!isValidCNPJ(cnpj)}
                        />
                    </ButtonsContainer>
                    <ButtonDownload onClick={handleDownloadSimulations}>                                        
                        <DownloadImage src={DownloadIcon} alt={'texto'} />
                        <DownloadText>Baixar todas as simulações</DownloadText>
                    </ButtonDownload>
                    </FadeIn>                    
                </ColumnForm>
            </ColumnsContainer>
            </motion.div>
            
            )}
            {showMapAddress && (                
                <motion.div                     
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "easeOut", duration: 2 }}
                >
                <Header 
                    enableLink={false} 
                    rightElementsStyle={showMapAddress ? 'default' :'white'} 
                    color='transparent'
                    clickAction={()=>{ 
                        setShowMapAddress(false);
                        setBodyColor(colors.mainGreen)
                    }}
                    fixWhiteBackgroundOnMobile
            />

           <MapPageContainer>               
                <MapContainer 
                    zoomControl={false} 
                    center={[currentAddress.lat , currentAddress.lon]} 
                    zoom={16} 
                    scrollWheelZoom={false}                    
                   
                >
                    <ChangeView center={[currentAddress.lat, currentAddress.lon]} zoom={16} /> 
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <ZoomControl position="bottomleft" />
                        <Marker position={[currentAddress.lat, currentAddress.lon]}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                        </Marker>
                </MapContainer>                   

                {/* <FadeInTransparency /> */}
               
                <ColumnAddress fadeIn={true} color={showMapAddress ? 'white' : colors.mainGreen}>
                    <FadeIn delay={50}>
                        <TextInput 
                            inputType={'cnpj'} 
                            placeholder={'CNPJ a ser simulado'} 
                            value={cnpj} 
                            setValue={setCnpj}
                        />
                    
                    <PostoTitle>{currentAddress.company_name}</PostoTitle>
                    </FadeIn>
                    <AddressContainer>                        
                        <AddressTitle>
                            Endereço 
                            <HoverIcon>
                                <FaPencilAlt size={30} onClick={()=> setEditAddress(!editAddress)}/>
                            </HoverIcon>
                        </AddressTitle>
                            {editAddress ? (<>
                            <FadeIn delay={100}>
                                <FormAddress onSubmit={handleSubmit}>
                                
                                    <AddressInputContainer width="30%">
                                        <AddressLabel htmlFor="postal_code" >CEP</AddressLabel>
                                        <AddressInput 
                                            type="text" 
                                            name="postal_code" 
                                            value={currentAddress.postal_code} 
                                            onChange={handleChange}
                                            placeholder="00000000"
                                            
                                        />
                                        
                                    </AddressInputContainer>

                                    <AddressInputContainer >
                                        <AddressLabel htmlFor="street_address">Logradouro</AddressLabel>
                                        <AddressInput 
                                            type="text" 
                                            name="street_address" 
                                            value={currentAddress.street_address} 
                                            onChange={handleChange}
                                            placeholder="Digite a rua e o número"
                                        />                                
                                    </AddressInputContainer>

                                    <AddressInputContainer width="30%">
                                        <AddressLabel htmlFor="district">Bairro</AddressLabel>
                                        <AddressInput 
                                            type="text" 
                                            name="district" 
                                            value={currentAddress.district} 
                                            onChange={handleChange}
                                            placeholder="Digite o bairro"
                                        />                                
                                    </AddressInputContainer>
                                    
                                    <AddressInputContainer width="30%"> 
                                        <AddressLabel htmlFor="city">Cidade</AddressLabel>
                                        <AddressInput 
                                            type="text" 
                                            name="city" 
                                            value={currentAddress.city} 
                                            onChange={handleChange}
                                            placeholder="Cidade"
                                        />
                                    </AddressInputContainer>

                                    <AddressInputContainer width="30%">
                                        <AddressLabel htmlFor="state">Estado</AddressLabel>                                
                                        <Select name='state' value={currentAddress.state} onChange={handleChange}>
                                            <option value=''>--</option>
                                            {estados.map((estado)=>(
                                                <option value={estado.sigla}>{estado.nome}</option>
                                            ))}
                                        </Select>
                                    </AddressInputContainer>

                                    <AddressInputContainer width="100%">
                                        <Button 
                                            text={'Salvar'} 
                                            colorScheme={'green-background-white-text'} 
                                            width={'30%'} 
                                            onPress={handleSubmit}
                                            type="submit"                                    
                                        />
                                    </AddressInputContainer>
                                                 
                                </FormAddress>                                
                                </FadeIn>  
                                </> 
                            ) : ( 
                            <FadeIn delay={100}>
                                <AddressLine>{currentAddress.street_address}</AddressLine>
                                <AddressLine>{currentAddress.district}</AddressLine>
                                <AddressLine upper>{currentAddress.city} - {currentAddress.state}</AddressLine>
                                <AddressLine>{currentAddress.postal_code}</AddressLine>
                            
                            </FadeIn>
                            )}                                                
                        </AddressContainer>
                    
                    <ButtonsContainer background="white" justifyContent='flex-end'>
                        <Button 
                            text={'Avançar'} 
                            colorScheme={'green-background-white-text'} 
                            width={'30%'} 
                            onPress={()=>{
                                // if(isValidCNPJ(cnpj)) {
                                if(true) {
                                    setRedirectToForm(true);
                                }
                            }}                                                      
                        />
                    </ButtonsContainer>
                </ColumnAddress>
          
                </MapPageContainer>
                </motion.div>  
               
            )}
</AnimatePresence>
        </Container>
        
    )
}

export default Home;