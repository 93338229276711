import styled from 'styled-components';

import {colors} from '../../constants/Colors';


export const Input = styled.input.attrs({
    className: ""
  })`
    border:none;
    border-bottom: 1px solid;
    border-color: ${colors.black};
    font-size: 26px;
    outline: none;
    width: 100%;
    height: 24px;
    color: ${props => props.placeholderTextColor || colors.black};
    background-color: transparent;
    margin-top: ${props => props.marginTop || '0px'};
    padding: 18px 0px;
    ::placeholder { 
        color: ${props => props.placeholderTextColor || colors.black};
        opacity: 1;
    }
`;


