import styled from 'styled-components';

export const RadioInput = styled.input`
    font-size: 16px;
    margin-right: 10px;
`;

export const RadioOptionsContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
        
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    align-items: center;
    
`;