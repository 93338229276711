import styled, { keyframes, css } from 'styled-components';
import { colors } from '../../../constants/Colors';

const SlideIn = keyframes`
    0% {left: 100%; }
    100% { 
        position: fixed;
        left: 8%;
        right: 0;
        bottom: 0; 
        z-index: 5000;        
    }
`;

export const ResultWindowContainer = styled.div`
    width: 100%;    
    height: 100%;
    background-color: white;    
    animation: ${SlideIn};
    animation-duration: 3s;
    animation-fill-mode: forwards;    
`;

export const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    
`;

export const ResultColumnsContainer = styled.div`
    display: flex;    
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-right: 20px;
    margin-left: 20px;
    height: 100%;
    flex: 1;
    overflow-y:scroll;

`;

export const ResultColumn = styled.div`
    height: 100%;
    display: flex;
    flex: 1 1 ${props=>props.width || '50%'};
    flex-direction: column;
    justify-content: space-around;
    /* align-items: flex-start; */
    position: relative;
    min-width: ${props=>props.width || '50%'};

`;

export const ResultColumnBreakPoint = styled(ResultColumn)`
    @media screen and (max-width: 990px) {
        display: none;               
    }
`;

export const SectionsBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 ${props=>props.width || '50%'};
    justify-content: space-around;
    width: ${props=>props.width || '50%'};

    /* @media screen and (max-width: 990px) {
        display: none;               
    } */

`;

export const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props=>props.align || 'center'};
    align-items: center;
    width: 100%;
    position: relative;
    padding-bottom: 16px;

`;

export const ContentRowBreakpoint = styled(ContentRow)`
    padding-right: 16px;
    @media screen and (min-width: 990px) {
        display: none;               
    }
`;

export const ContentRowColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SmallTitle = styled.span`
    color: #212121;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 900;
    color: ${colors.darkGray};
`;

export const Value = styled.span`
    color: ${(props)=> props.color};
    font-size: 32px;
    font-weight: 700;
`;

export const ValueUnity = styled.span`
    color: ${colors.darkGray};
    font-size: 24px;
    margin-left: 15px;
    font-weight: bold;
`;

export const ValueRow = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h3`
    font-weight: bold;
    margin-bottom: 20px;
`;

export const SavingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #5F5F5F26;
    border-radius: 40px;
    max-width: 350px;
    margin-right: 16px;    

    @media screen and (max-width: 780px) {
        max-width: 80%;       
    }
`;

export const NameInput = styled.input`
    border: none;
    color: #212121;
    font-size: 20px;
    margin-left: 24px;
    outline: none;
    max-width: 200px;
`;

export const SaveButton = styled.button`
    padding: 16px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    padding:16px;    
    justify-content: center;
    align-items: center;
    cursor:pointer;
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.borderColor || colors.mainGreen};
    min-width:  ${(props) => props.width || '100px'};
    color:  ${props=> props.color || colors.white};
    background-color: ${(props) => props.backgroundColor || colors.mainGreen};
    z-index: 999;
    border-radius: 45px;
    :hover {
        background-color: #E3E3E3;
        color: ${colors.mainGreen};
    }
`;

export const FormHeader = styled.div`
    height: 80px;    
    margin: 40px;    
    background-color: white;       
`;

export const FormTitle = styled.h1`
    font-size: 30px;
    color: #606060;
    text-transform: uppercase;
    font-weight: 700;

    @media screen and (max-width: 780px) {
        font-size: 20px;
    }      
`;

export const CloseIcon = styled.div`
    position: absolute;
    left: 10px;
    top: 10px;    
`;