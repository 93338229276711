import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
 } 

 body {
    background-color: ${props => (props.bodyColor ? props.bodyColor : 'white')};
 }
`;

const styleTag = tag => css => () => <style type="text/css">
  {tag} {"{"}
    {css}
  {'}'}
</style>;

export default {
  body: styleTag('body')
}

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 990px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const ErrorContainer = styled.div`
    color: red;
    font-size: 12px;
    margin-top: 10px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

