import Axios from 'axios';
import { getToken, getUserAgent } from '../services';
//import { CLIENT_ID, AUTHORIZATION } from '../../../../env.json';
//import { isSignedIn, valueToken, onSignOut } from '../auth';
import {
  ERROR_CONNECTION,
  ERROR_INTERNAL,
  ERROR_ACCESS_DANIED,
  ERROR_NOT_FOUND,
  GET,
  POST,
  DELETE,
  PUT,
  PATCH,
  GET_COOKIE,
  LOGIN,
  DOWNLOAD,
} from './Constants';

export const headerRequests = async () => {
  let accessToken = getToken();

  if(accessToken) {
    let userAgent = getUserAgent();
    return ({
      'User-Agent': userAgent,
      'Authorization': accessToken,    
      'Content-Type': 'application/json',
    });
  }
  return ({
    'Content-Type': 'application/json',
  });
};

export default class Connect {
  static async call(url, method, body) {
    let answer;
    let config = {};

    const headers = await headerRequests(method);
    
    if(method === DOWNLOAD) {
      config = {
        headers,
        responseType: 'blob'
      };  
    } else {
      config = {
        headers,
      };
    }

    switch (method) {
      case GET:
        answer = Axios.get(url, config);
        break;
      case DOWNLOAD:
        answer = Axios.get(url, config);
        break;
      case POST:        
        answer = Axios.post(url, body, config);
        break;
      case DELETE:
        answer = Axios.delete(url, config);
        break;
      case PUT:
        answer = Axios.put(url, body, config);
        break;
      case PATCH:
        answer = Axios.patch(url, body, config);
        break;      
      case LOGIN:
        answer = Axios.post(url, body, {
          headers: {
          //  Authorization: AUTHORIZATION,
            'Content-Type': 'application/json',
          },
        });
        break;
      default:
        answer = 'Método HTTP usado para conectar a API é inválido.';
    }

    return answer;
  }

  static async connect(url, method, body) {
    return Connect.call(url, method, body).catch((error) => {
      if (error.response) {
        switch (error.response.status) {
          case 403:
            //onSignOut();
            throw new Error(ERROR_ACCESS_DANIED);
          case 401:
            throw new Error(ERROR_ACCESS_DANIED);
          case 404:
            throw new Error(ERROR_NOT_FOUND);
          case 400:
          case 422:
            if (error.response.data && error.response.data.message) {
              throw new Error(error.response.data.message);
            }
            break;
          case 500:
            if (error.response.data && error.response.data.mensagem
              && Connect.deveExibirMensagem(error.response.data.mensagem)) {
              throw new Error(error.response.data.mensagem.replace(/([a-zA-Z]+)?Exception: /, ''));
            }
            throw new Error(ERROR_INTERNAL);
          default:
            throw new Error(ERROR_CONNECTION);
        }
      }
      throw new Error(ERROR_CONNECTION);
    });
  }
}

export async function ConnectApiPost(url, body) {
  return Connect.connect(url, POST, body);
}

export async function ConnectApiPUT(url, body) {
  return Connect.connect(url, PUT, body);
}

export async function ConnectApiPatch(url, body) {
  return Connect.connect(url, PATCH, body);
}

export async function ConnectApiGet(url) {
  return Connect.connect(url, GET);
}

export async function ConnectApiGetWithCookie(url, codeWarehouseDelivery, sellers = '') {
  const body = { codeWarehouseDelivery, sellers };
  return Connect.connect(url, GET_COOKIE, body);
}

export async function ConnectApiDelete(url) {
  return Connect.connect(url, DELETE);
}

export async function ConnectApiPostLogin(url, body) {
  return Connect.connect(url, LOGIN, body);
}


export async function ConnectApiDownload(url) {
  return Connect.connect(url, DOWNLOAD);
}