import styled from 'styled-components';


export const Input = styled.input`

    padding: 10px;
    border: 0.5px solid;
    border-radius: 6px 6px 6px 6px;
    outline-color: #14B352;
    width: 100px;

    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }    
`;

