import styled, { keyframes } from 'styled-components';
import {colors} from '../../../constants/Colors';

export const FormContentWrapper = styled.div`
    height: 100%;
`;

export const FormContainer =  styled.div`    
    display: flex;    
    flex-direction: column;
    width: 100% - 350px;     
`;

export const FormColumns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% - 350px; 

   
    
    @media screen and (max-width: 990px) {
        flex-direction: column;               
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        width: 100%;       
    }
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    /* flex: 1 1 45%; */
    padding-right: 50px;

    @media screen and (max-width: 990px) {
        padding-right: 0px;      
    }
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    /* flex: 1 1 45%; */
    padding-left: 50px;

    @media screen and (max-width: 990px) {
        padding-left: 0px;      
    }
`;


export const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
`;

export const QuestionText = styled.p`
    color: #212121;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: bold;
`;

export const QuestionSubText = styled.p`
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #606060;
`;

export const HighlightedText = styled.span`
    font-weight: bold;
    color: #14B352;
`;

export const RowOptions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    justify-content: space-between;   
`;

export const SidedIconLabel = styled.div`
    display: flex;
`;

export const SectionTitle = styled.div`
    /* color: #14B352; */
    color: ${props=>props.color|| 'grey'};
    font-weight: 700;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const IconCircleContainer = styled.div`
    /* background-color: #14B352; */

    background-color: ${props=>props.bgColor|| 'grey'};

    border-radius: 40px;
    min-height: ${props => `${props.size}px`};
    min-width: ${props => `${props.size}px`};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const HR = styled.hr`
    margin-top: 40px;
`;

const SlideIn = keyframes`
    0% {left: 100%; }
    100% { 
        position: fixed;
        left: 10%;
        right: 0;
        bottom: 0; 
        z-index: 1000;
    }
`;

export const ResultsWindow = styled.div`
    width: 90%;
    /* position: absolute; */
    /* left: 100%; */
    height: 100%;
    background-color: yellow;    
    animation: ${SlideIn};
    animation-duration: 3s;
    animation-fill-mode: forwards;
    
`;

export const FormTitle = styled.h1`
    font-size: 39px;
    color: #606060;
    text-transform: uppercase;
    font-weight: 700;        
`;


export const TitleCNPJ = styled.p`
    font-size: 29px;
    margin-bottom: 10px;
    
`;

export const FormHeader = styled.div`
    height: 100px;    
    /* position: fixed; */
    /* top: 100px; */
    /* left: 0; */
    /* flex: 1 1; */
    width: 100%;
    /* z-index: -1; */
    
    /* margin-left: 50px; */
    background-color: white;
`;



export const InternalHeaderLogo = styled.div`
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
`;
export const ExternalLink = styled.a`
    text-decoration: none;

    &:visited {
        text-decoration: none;
    }
`;

export const FormSideBar = styled.div`
    height: 100vh;        
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    z-index: 3000;
    box-shadow: 0px 15px 18px #888888;
    padding-top: 100px;        
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media screen and (max-width: 780px) {
        position: static;
        height: auto;
        width: 100%;
        box-shadow: none;
        padding-top: 16px;
        padding-bottom: 16px;        
    }

    /* @media screen and (max-width: 990px) {
        position: static;
        height: auto;
        width: 100%;
        box-shadow: none;
        padding-top: 16px;        
    } */
    
`;

export const BlockInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    left: -20px;

    @media screen and (max-width: 780px) {
        display: none;       
    }
`;

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: ${props=>props.width || '100%'}; 
    max-width: 350px;  
`;

export const InputControl = styled.input`
    
    height: ${props => props.height || '40px'};
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;    
    justify-content: center;
    align-items: center;
    cursor:pointer;
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.borderColor || colors.mainGreen};
    width:  ${(props) => props.width || '40%'};
    color:  ${(props) => props.textColor || colors.mainGreen};
    background-color: ${(props) => props.backgroundColor || colors.white};
    z-index: 999;
    border-radius: 45px;
    white-space: normal;

    :hover {
        background-color: ${props => props.hoverBackground || colors.darkGray};
        color: ${props => props.hoverTextColor || colors.white};
    }
`; 

export const DescriptionBox = styled.div`
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 6px 6px 6px 6px;    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    /* width: 250px; */
    width: 85%;
    position: relative;
    left: 10px;
    font-weight: bold;
    padding: 16px;
    
    &:before {
    content: "";
    width: 0;
    height: 0;
    left: -10px;
    top: 30px;
    position: absolute;
    border: 10px solid #F5F5F5;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const Modal = styled.div`
    z-index: 4000;
	display: ${({show}) => (show ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width:100vw;
	background: rgba(0,0,0,0.5);
`;