import React from 'react';
import { onlyNumbers, cnpjMask } from '../../utils/functions/string';
import {colors} from '../../constants/Colors';

import { 
    Input,
} from './styles';

const TextInput = ({
    placeholder = '',
    value,
    setValue, 
    inputType = 'text',
    placeholderTextColor = colors.black,
    marginTop = '0px', 
}) => {

    const setValueTypeWithMask = (value) =>{
        if (inputType === 'number'){
            setValue(onlyNumbers(value));
            return;
        }
        if (inputType === 'cnpj'){
            setValue(cnpjMask(onlyNumbers(value)));
            return;
        }

        setValue(value)
    }
    
    return (
        <Input 
            placeholderTextColor= {placeholderTextColor} 
            placeholder={placeholder} 
            value={value} 
            onChange={(e) => setValueTypeWithMask(e.target.value)}
            marginTop = {marginTop}
            type={inputType}    
        />
    )
}



export default TextInput;