import styled from 'styled-components';

export const FormHeader = styled.div`
    /* height: 80px;     */
    position: fixed;
    top: 100px;
    margin: 0;    
    margin-right: 350px;
    z-index: 600;
    background-color: white;
    width: calc(100% - 400px);
    padding-bottom: 10px;
    

    @media screen and (max-width: 780px) {
        margin-right: auto;
        width: 100%;      
    }
`;

export const HeaderContainer = styled.div`
    height: 100%;
    min-height: 40px;
`; 

export const FormTitle = styled.h1`
    font-size: 30px;
    color: #606060;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 10px;
    padding-right: 10px;      

    @media screen and (max-width: 990px) {
        font-size: 22px;      
    } 
`;

export const InternalHeaderLogo = styled.div`
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
`;

export const TitleCNPJ = styled.p`
    font-size: 24px;
    margin: 0px;
    margin-bottom: 10px;    
`;


export const ContentRow = styled.div`
    
`;

export const FormContainer = styled.div`
    height: 100%;
`;

export const FormPage = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    margin-right: 350px;

    @media screen and (max-width: 780px) {
        margin-right: auto;       
    }
`

export const BlockInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    left: -20px;
`;

export const BlockDescription = styled.span`

`;


export const Icon = styled.div`

`;

export const DescriptionBox = styled.div`
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 4px 4px 4px 4px;    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 300px;
    position: relative;
    left: 10px;
    font-weight: bold;
    padding: 16px;
    

    &:before {
    content: "";
    width: 0;
    height: 0;
    left: -10px;
    top: 30px;
    position: absolute;
    border: 10px solid #F5F5F5;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

