import { removeFormat } from './string'

export const formatBodySaveSimulation = (formValues, formName, resultData) => {
    return  {        
        "cnpj": formValues.cnpj,
        "simulation_name": formName, 
        "question_01":formValues.question_01,
        "question_02":formValues.question_02,
        "question_03":formValues.question_03,
        "question_04":formValues.question_04,
        "question_05":formValues.question_05,
        "question_06":formValues.question_06,
        "question_12":formValues.question_12,
        "question_13":formValues.question_13,
        "question_07":formValues.question_07 === 'Sim' ? true : false,
        "question_08":formValues.question_08 === 'Sim' ? true : false,
        "question_09":formValues.question_09 === 'Sim' ? true : false,
        "question_10":formValues.question_10 === 'Sim' ? true : false,
        "question_11":formValues.question_11 === 'Sim' ? true : false,
        "question_39":formValues.question_39,
        "question_14":formValues.question_14,
        "question_15":formValues.question_15,
        "question_16":formValues.question_16,
        "question_17":formValues.question_17 === 'Sim' ? true : false,        
        "question_18":formValues.question_18,
        "question_19":formValues.question_19,
        "question_20":formValues.question_20,
        "question_21":formValues.question_21,
        "question_22":formValues.question_22,
        "question_23":formValues.question_23,
        "question_24":formValues.question_24,
        "question_25":formValues.question_25,
        "question_26":formValues.question_26,
        "question_27":formValues.question_27,
        "question_28":formValues.question_28,
        "question_29":formValues.question_29,
        "question_30":formValues.question_30,
        "question_31":formValues.question_31,
        "question_32":formValues.question_32,
        "question_33":formValues.question_33,
        "question_34":formValues.question_34,
        "question_35":formValues.question_35,
        "question_36":formValues.question_36,
        "question_37":formValues.question_37,
        "question_38":formValues.question_38,
        "question_44":formValues.question_44,
        "question_40":formValues.question_40,
        "question_41":formValues.question_41,
        "question_42":formValues.question_42,
        "question_43":formValues.question_43,
        "question_45":formValues.question_45,        
        "etanol":resultData.etanol,
        "gasolina":resultData.gasolina, 
        "diesel":resultData.diesel
    }
};

export const formatBodyPredict = (formValues) => {
    return  {        
        "cnpj": formValues.cnpj,        
        "question_01":formValues.question_01,
        "question_02":formValues.question_02,
        "question_03":formValues.question_03,
        "question_04":formValues.question_04,
        "question_05":formValues.question_05,
        "question_06":formValues.question_06,
        "question_12":formValues.question_12,
        "question_13":formValues.question_13,
        "question_07":formValues.question_07 === 'Sim' ? true : false,
        "question_08":formValues.question_08 === 'Sim' ? true : false,
        "question_09":formValues.question_09 === 'Sim' ? true : false,
        "question_10":formValues.question_10 === 'Sim' ? true : false,
        "question_11":formValues.question_11 === 'Sim' ? true : false,
        "question_39":formValues.question_39,
        "question_14":formValues.question_14,
        "question_15":formValues.question_15,
        "question_16":formValues.question_16,
        "question_17":formValues.question_17 === 'Sim' ? true : false,        
        "question_18":formValues.question_18,
        "question_19":formValues.question_19,
        "question_20":formValues.question_20,
        "question_21":formValues.question_21,
        "question_22":formValues.question_22,
        "question_23":formValues.question_23,
        "question_24":formValues.question_24,
        "question_25":formValues.question_25,
        "question_26":formValues.question_26,
        "question_27":formValues.question_27,
        "question_28":formValues.question_28,
        "question_29":formValues.question_29,
        "question_30":formValues.question_30,
        "question_31":formValues.question_31,
        "question_32":formValues.question_32,
        "question_33":formValues.question_33,
        "question_34":formValues.question_34,
        "question_35":formValues.question_35,
        "question_36":formValues.question_36,
        "question_37":formValues.question_37,
        "question_38":formValues.question_38,
        "question_44":formValues.question_44,
        "question_40":formValues.question_40,
        "question_41":formValues.question_41,
        "question_42":formValues.question_42,
        "question_43":formValues.question_43,
        "question_45":formValues.question_45,            
    }
};

export const formatBodyInitialValues = (formValues) => {
    return  {        
        "cnpj": formValues.cnpj,        
        "question_01":formValues.question_01,
        "question_02":formValues.question_02,
        "question_03":formValues.question_03,
        "question_04":formValues.question_04,
        "question_05":formValues.question_05,
        "question_06":formValues.question_06,
        "question_12":formValues.question_12,
        "question_13":formValues.question_13,
        "question_07":formValues.question_07 === true ?  'Sim' : formValues.question_07 === false ? 'Não' : undefined,
        "question_08":formValues.question_08 === true ?  'Sim' : formValues.question_08 === false ? 'Não' : undefined,
        "question_09":formValues.question_09 === true ?  'Sim' : formValues.question_09 === false ? 'Não' : undefined,
        "question_10":formValues.question_10 === true ?  'Sim' : formValues.question_10 === false ? 'Não' : undefined,
        "question_11":formValues.question_11 === true ?  'Sim' : formValues.question_11 === false ? 'Não' : undefined,
        "question_39":formValues.question_39,
        "question_14":formValues.question_14,
        "question_15":formValues.question_15,
        "question_16":formValues.question_16,
        "question_17":formValues.question_17 === true ?  'Sim' : formValues.question_17 === false ? 'Não' : undefined,
        "question_18":formValues.question_18,
        "question_19":formValues.question_19,
        "question_20":formValues.question_20,
        "question_21":formValues.question_21,
        "question_22":formValues.question_22,
        "question_23":formValues.question_23,
        "question_24":formValues.question_24,
        "question_25":formValues.question_25,
        "question_26":formValues.question_26,
        "question_27":formValues.question_27,
        "question_28":formValues.question_28,
        "question_29":formValues.question_29,
        "question_30":formValues.question_30,
        "question_31":formValues.question_31,
        "question_32":formValues.question_32,
        "question_33":formValues.question_33,
        "question_34":formValues.question_34,
        "question_35":formValues.question_35,
        "question_36":formValues.question_36,
        "question_37":formValues.question_37,
        "question_38":formValues.question_38,
        "question_44":formValues.question_44,
        "question_40":formValues.question_40,
        "question_41":formValues.question_41,
        "question_42":formValues.question_42,
        "question_43":formValues.question_43,
        "question_45":formValues.question_45,               
    }
};

export const formatBodyAddress = (cnpj, address) => {
    return {
        "cnpj": removeFormat(cnpj),
        "street_address": address.street_address,
        "city": address.city,
        "state": address.state,
        "postal_code": address.postal_code,
	    "district": address.district
    }
}

export const formatBodyAddressData = (formValues) => {
    return {
        "cnpj": removeFormat(formValues.cnpj),
        "street_address": formValues.street_address,
        "city": formValues.city,
        "state": formValues.state,
        "postal_code": formValues.postal_code,
        "district": formValues.district,
        "company_name": formValues.company_name,
    }
}


export const formatBodyDefaultValues = (result) => {
    return {
        question_01: result['BICOS - GASOLINA'],
        question_02: result['BICOS - ETANOL'],
        question_03: result['BICOS - DIESEL'],
        question_04: result['TANCAGEM - GASOLINA'],
        question_05: result['TANCAGEM - ETANOL'],
        question_06: result['TANCAGEM - DIESEL'],
    }
    
}