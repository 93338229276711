import {ConnectApiPost, ConnectApiGet, ConnectApiPostLogin} from '../api';
import jwtDecode from 'jwt-decode';
import fileDownload from 'js-file-download';
import {
    ERROR_ACCESS_DANIED,
  } from './connectionAPI/Constants';
import { ConnectApiDownload } from './connectionAPI/ConnectionAPI';

const ENVIRONMENT = 'https://vibraenergia.simulador.bigdata.com.br/api/';
//DEVELOPMENT = http://35.171.98.129/api/


const getResultAPIPost = async (endpoint, body) =>{
    try{
        const returnService =  await ConnectApiPost(ENVIRONMENT+endpoint, body);
        return returnService.data;
    }catch(e){
        if(e === ERROR_ACCESS_DANIED) {
            logout();
        }
        throw new Error(e.message)
    }   
}

const getResultAPIGET = async (endpoint) =>{
    try{
        const returnService =  await ConnectApiGet(ENVIRONMENT+endpoint);
        return returnService.data;
    }catch(e){
        if(e === ERROR_ACCESS_DANIED) {
            logout();
        }
        throw new Error(e.message)
    }   
   
}

export const getPredict = async (body) => {
   const getPredictEndpoint = 'predict';
   return getResultAPIPost(getPredictEndpoint, body);
};

export const getAddress = async (body) => {
    const getAdressEndpoint = 'get_address';
    return getResultAPIPost(getAdressEndpoint, body);
};

export const saveAddress = async (body) => {    
    const saveAddressEndpoint = 'save_address';
    return getResultAPIPost(saveAddressEndpoint, body);
};

export const getSimulation = async (body) => {    
    const getSimulationEndpoint = 'get_simulation';
    return getResultAPIPost(getSimulationEndpoint, body);
};

export const saveSimulation = async (body) => {    
    const saveSimulationEndpoint = 'save_simulation';
    return getResultAPIPost(saveSimulationEndpoint, body);
};

export const getDefaultValues = async (body) => {
    const getDefaultValuesEndpoint = 'get_default_values';
    return getResultAPIPost(getDefaultValuesEndpoint, body);
};

export const getSimulationList = async () => {
    const getSimulationListEndpoint = 'get_simulation_list';
    return await getResultAPIGET(getSimulationListEndpoint);
};

export const downloadSimulations = async (filename) => {
    const downloadSimulationsEndpoint = 'get_all_simulations';
    try{
        const returnService =  await ConnectApiDownload(ENVIRONMENT+downloadSimulationsEndpoint);
        fileDownload(returnService.data, filename);
    }catch(e){
        if(e === ERROR_ACCESS_DANIED) {
            logout();
        }
        console.log(e.message);
        throw new Error(e.message)
    }   
};


export const isAuthenticated = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if(token) {
        try{
            const decodedToken = jwtDecode(token);
            if(decodedToken && decodedToken.exp < (Date.now() / 1000)) {
                logout();
                return false;
            } else {
                return true;
            }
        } catch (e) {
            console.log(e);
        }            
    }    
    return false;
}

export const TOKEN_KEY = "simulaBr";

export const getToken = () => {

    const token = localStorage.getItem(TOKEN_KEY);
    if(token) {
        try{
            const decodedToken = jwtDecode(token);
            if(decodedToken && decodedToken.exp < (Date.now() / 1000)) {
                logout();
                return false;
            } else {
                return token;
            }
        } catch (e) {
            console.log(e);
        }            
    }    
    return null;
}

export const saveLogin = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const USER_AGENT = "userAgent";

export const getUserAgent = () => {

    const token = localStorage.getItem(USER_AGENT);

    return token;
}

export const saveUserAgent = user_agent => {
    localStorage.setItem(USER_AGENT, user_agent);
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
};

export const login = async (body) => {
    const response = await ConnectApiPostLogin(ENVIRONMENT+'login', body);
    return response.data;
}

export const avacalhaToken = () => {
    localStorage.setItem(TOKEN_KEY, 'lalala');
}
