import React from 'react';
import { colors } from '../../constants/Colors';
import { 
    ButtonStyled,
} from './styles';

const Button = ({
    text = '',
    colorScheme = 'white-background-green-text',
    width = '100%',
    onPress,
    disabled=false,
    ...rest
}) => {

    const getButtonType = () =>{
        switch(colorScheme){
            case 'green-background-white-text':
                return(
                    <ButtonStyled
                        textColor={colors.white}
                        backgroundColor={(disabled) ? colors.darkGray : colors.mainGreen}
                        borderColor ={colors.white}
                        width= {width}
                        onClick={onPress}
                        hoverTextColor={colors.mainGreen}
                        hoverBackground="#F5F5F5"
                        hoverBorderColor="white"
                        {...rest}
                    >
                        {text}
                    </ButtonStyled>
                    )
            case 'grey-background-white-text':
                return(
                    <ButtonStyled
                        textColor={colors.white}
                        backgroundColor={colors.darkGray}
                        borderColor ={colors.white}
                        width= {width}
                        onClick={onPress}
                        hoverTextColor={colors.darkGray}
                        hoverBackground="#F5F5F5"
                    >
                        {text}
                    </ButtonStyled>
                    )
            
            case 'white-background-green-text':
            default :
                return(
                    <ButtonStyled
                        textColor={colors.mainGreen}
                        backgroundColor={(disabled) ? colors.darkGray : colors.white}
                        borderColor ={colors.mainGreen}
                        width= {width}
                        onClick={onPress}
                        hoverTextColor={colors.white}
                        hoverBackground={colors.mainGreen} 
                    >
                        {text}
                    </ButtonStyled>
                )
        }
    }
        return (
            <>
                {getButtonType()}
            </>
        )

}



export default Button;