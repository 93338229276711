import React from 'react';
import {
    Field, ErrorMessage
} from 'formik';
import { Input } from './styles';
import {
    ErrorContainer,
    InputContainer
} from '../../globalStyles';

export const NumberInput = ({fieldName, label}) => {
    return (
        <>
            <label htmlFor={fieldName}>{label}</label>
            <Field name={fieldName}>
                {({ field }) => (
                    <InputContainer>                    
                        <Input type="number" min="0" max="500" {...field}/>                                        
                        <ErrorContainer><ErrorMessage name={fieldName} /></ErrorContainer>
                    </InputContainer>
                )}
            </Field>

        </>
    )
}

export default NumberInput;
