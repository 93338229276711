import styled from 'styled-components';

import {colors} from '../../constants/Colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;    
    width: 100vw;
    height: 100vh;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
    width: 100%;
`;

export const ColumnsContainer = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    background-color: white;        
    width: 100%;
    height: 100%;
    flex-wrap: wrap

    
`;

export const Column = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.white};   
    padding: 0px 32px;
    min-width: 500px;    
    min-height: 500px;
    position: relative;
`;

export const LeftImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 600px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
`;


export const HelloText = styled.span`
    font-size: 90px;
    font-weight: bold;
    color: ${colors.mainGreen};
`;

export const WelcomeText = styled.p`
    font-size: 32px;
    font-weight: bold;
    color: ${colors.mainGreen};
    margin-right: 16px;
`;


export const GetStartedText = styled.p`
    margin-top: 30px;
    font-size: 20px;
    font-weight: medium;
    color: ${colors.mainGreen};
    margin-right: 16px;
    margin-bottom: 24px;
`;
