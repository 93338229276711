import React, { useState } from 'react';
import {
    FaGasPump,
    FaParking,
    FaUserAlt,
    FaCreditCard,
    FaMapMarkerAlt,
    FaRoad,
    FaBuilding,
    FaHandHolding,
    FaOilCan
} from 'react-icons/fa';

import FadeIn from 'react-fade-in';
import { colors } from '../../../constants/Colors';

import {
    Formik, Form
} from 'formik';

import { getPredict } from '../../../api/services';

import { 
    MinusPlusInput,
    NumberInput,
    RadioGroup,
    SelectOption,
    Checkbox,
    Alert
} from '../../../components/';

import { formatBodyPredict } from '../../../utils/functions/createBody';

import ResultWindow from '../ResultWindow';

import {
    FormContainer,
    QuestionContainer,
    QuestionText,
    QuestionSubText,
    HighlightedText,
    RowOptions,
    LeftColumn,
    RightColumn,
    SectionTitle,
    FormColumns,
    IconCircleContainer,
    SidedIconLabel,
    HR,
    FormSideBar,
    InternalHeaderLogo,
    BlockInfo,
    DescriptionBox,
    ControlsContainer,
    InputControl,
    Modal,
    ExternalLink,
    FormContentWrapper,
} from './styles';

import { LogoPoweredby, LogoDescription, Logo  } from '../../../components/Header/styles';
import LogoBigDataDefault from '../../../images/logo_big_data.png';
import { WARNING, ERROR } from '../../../constants/Constants';

import { Waypoint } from 'react-waypoint';

const Question = ({text, subtext, options}) => (
    <QuestionContainer>
        <QuestionText>
            {text()}
        </QuestionText>
        {subtext ? ( <QuestionSubText>
                {subtext()}             
            </QuestionSubText>
        ) : null}
        
        {options()}
    </QuestionContainer>
);

const FormContent = ({initialValues, validationSchema, company_name}) => {
    const [showResults, setShowResults ] = useState(false);
    const [resultPredic, setResultPredict] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [displayAlert, setDisplayAlert] = useState(null);
    const [visitedSections, setVisitedSections] = useState([1,0,0]);    

    const handleAlert = (alertObj, duration) => { 
        setDisplayAlert(alertObj);
        setTimeout(() => {
            setDisplayAlert(null);            
          }, duration);
    };

    return (
        <>
        {displayAlert ? 
            <Alert 
                close={()=>setDisplayAlert(null)}
                type={displayAlert.type} 
                message={displayAlert.message} 
        /> : null}    
        <FormContentWrapper>
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values) =>{                                
                try {
                    const result = await getPredict(formatBodyPredict(values));                    
                    setResultPredict(result);
                    setFormValues(values);
                    setShowResults(true);
                } catch (e) {
                    handleAlert({
                        type: ERROR,
                        message: e.message,
                        closeEnable:true
                    }, 7000);
                }                                                
            } }
            initialValues={initialValues}
            validationSchema={validationSchema}            
        >
        {({resetForm, errors, isSubmitting }) => (
            <Form>
                { showResults ? (
                    <>
                        <Modal show/>
                        <ResultWindow 
                            resultData={resultPredic}
                            formValues={formValues}
                            company_name={company_name}
                            closeResultWindow={()=>setShowResults(false)}
                        >
                                <h1>Entrou a tela</h1>
                        </ResultWindow>
                    </>
                ) : null}
                { Object.keys(errors).length > 0 && !isSubmitting ? 
                    <>
                    <Alert
                        type={WARNING}
                        message='Há campos obrigatórios não preenchidos no formulário'
                        fadeOut
                        duration={6}
                    />
                    </> 
                : null }               
                <FormContainer>
                    <FadeIn delay={200}>
                    <SectionTitle color={visitedSections[0]?colors.mainGreen:'grey'}>
                        <IconCircleContainer bgColor={visitedSections[0]?colors.mainGreen:'grey'} size={70}>
                            <FaGasPump color="white" size={40}/>
                        </IconCircleContainer>                        
                        Funcionamento e estrutura
                    </SectionTitle>
                
                    <Waypoint 
                        onEnter={()=>setVisitedSections([1,0,0])} 
                    >
                    <FormColumns>                    
                    <LeftColumn>
                        <Question 
                            text={()=>"Qual o número de bicos que o posto possui para cada combustível a seguir?"}
                            subtext={()=> ( <> 
                                Os valores iniciais são dados retirados da ANP, 
                                altere somente se estiverem diferente da 
                                realidade do posto </>)}
                            options={()=>(
                                <RowOptions>
                                <MinusPlusInput fieldName='question_01' min={0} max={500} label="Gasolina"/>
                                <MinusPlusInput fieldName='question_02' min={0} max={500} label="Etanol"/>
                                <MinusPlusInput fieldName='question_03' min={0} max={500} label="Diesel"/>
                            </RowOptions>
                            )} 
                        />
                        <Question 
                            text={()=><>Qual a capacidade de tancagem (m<sup>3</sup>) para combustível a seguir?</>}
                            subtext={()=> `Os valores iniciais são dados retirados da ANP,altere somente se estiverem diferente da 
                            realidade do posto.`}
                            options={()=>(
                                <RowOptions>
                                    <MinusPlusInput fieldName='question_04' min={0} max={500} label="Gasolina"/>
                                    <MinusPlusInput fieldName='question_05' min={0} max={500} label="Etanol"/>
                                    <MinusPlusInput fieldName='question_06' min={0} max={500} label="Diesel"/>
                                </RowOptions>
                            )} 
                        />                        
                        <Question 
                            text={()=>"Número de vagas de estacionamento"}
                            subtext={()=>"Considere apenas as vagas de estacionamento e não posições de abastecimento. "}
                            options={()=>(
                                <RowOptions>
                                <SidedIconLabel>
                                    <FaParking color="#14B35280" size={32} style={{marginRight:10}}/>
                                    <NumberInput fieldName={"question_12"} />
                                </SidedIconLabel>
                            </RowOptions>
                            )}
                        />                        
                        <Question 
                            text={()=>"Número total de frentistas"}
                            subtext={()=>"Considerando todos os turnos."}
                            options={()=>(
                                <RowOptions>
                                <SidedIconLabel>
                                    <FaUserAlt color="#14B35280" size={32} style={{marginRight:10}}/>
                                    <NumberInput fieldName={"question_13"} />
                                </SidedIconLabel>
                            </RowOptions>
                            )}
                        />
                    </LeftColumn>
                    <RightColumn>
                        <Question 
                            text={()=>( <> O posto funciona <HighlightedText>24 horas</HighlightedText>? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <RadioGroup 
                                        fieldName={"question_07"}
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />                                    
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto funciona aos <HighlightedText>domingos</HighlightedText>? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <RadioGroup 
                                        fieldName="question_08"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto funciona aos <HighlightedText>feriados</HighlightedText>? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <RadioGroup 
                                        fieldName="question_09"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto possui <HighlightedText>caixa eletrônico</HighlightedText>? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <RadioGroup 
                                        fieldName="question_10"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto conta com serviço de segurança próprio? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <FaUserAlt color="#14B35280" size={32} style={{marginRight:10}}/>
                                    <RadioGroup 
                                        fieldName="question_11"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto aceita cartão de crédito e débito para pagamento do abastecimento? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <FaCreditCard color="#14B35280" size={32} style={{marginRight:10}}/>
                                    <RadioGroup 
                                        fieldName="question_39"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Apenas débito", label: "Apenas débito"},
                                            {value: "Apenas crédito", label: "Apenas crédito"}
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                    </RightColumn>
                    </FormColumns>
                    </Waypoint>
                    <HR />
                    <SectionTitle color={visitedSections[1]?colors.mainGreen:'grey'}>
                        <IconCircleContainer bgColor={visitedSections[1]?colors.mainGreen:'grey'} size={70}>
                            <FaMapMarkerAlt color="white" size={40}/>
                        </IconCircleContainer>                        
                        Localização do posto
                    </SectionTitle>
                    <Waypoint 
                        onEnter={()=>setVisitedSections([1,1,0])}                                           
                        >
                    <FormColumns>
                        <LeftColumn>
                        <Question 
                            text={()=>( <> O posto está em uma rodovia? </>)}                            
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <FaRoad color={visitedSections[1]?colors.iconGreen:'grey'} size={32} style={{marginRight:10}}/>
                                    <SelectOption 
                                        fieldName="question_14"
                                        options={[
                                            {value: "--", label: "--"},
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"},
                                            {value: "Talvez", label: "Talvez"}
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>"Quantas faixas de carro existem na via principal?"}
                            subtext={()=>"Escreva apenas o número."}
                            options={()=>(
                                <RowOptions>
                                <SidedIconLabel>                                    
                                    <NumberInput fieldName={"question_15"} />
                                </SidedIconLabel>
                            </RowOptions>
                            )}
                        />                       
                        </LeftColumn>
                        <RightColumn>
                        </RightColumn>                        
                    </FormColumns> 
                    </Waypoint>
                    <HR />
                    <SectionTitle color={visitedSections[2]?colors.mainGreen:'grey'}>
                        <IconCircleContainer bgColor={visitedSections[2]?colors.mainGreen:'grey'} size={70}>
                            <FaHandHolding color="white" size={40}/>
                        </IconCircleContainer>                        
                        Ofertas e serviços
                    </SectionTitle>
                    <Waypoint onEnter={()=>setVisitedSections([1,1,1])}>
                    <FormColumns>
                        <LeftColumn>
                        <Question 
                            text={()=>( <> O posto possui loja de conveniência? </>)}
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <FaBuilding color={visitedSections[2]?colors.iconGreen:'grey'} size={32} style={{marginRight:10}}/>
                                    <SelectOption 
                                        fieldName="question_16"
                                        options={[
                                            {value: "--", label: "--"},
                                            {value: "Sim, e é BR Mania", label: "Sim, e é BR Mania"},
                                            {value: "Sim, mas não é BR Mania", label: "Sim, mas não é BR Mania"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <>A loja de conveniência funciona 24 horas? </>)}
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <RadioGroup 
                                        fieldName="question_17"
                                        options={[
                                            {value: "Sim", label: "Sim"},
                                            {value: "Não", label: "Não"},                                            
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> O posto oferece serviço de troca de óleo? </>)}
                            options={()=>(
                                <RowOptions>
                                    <SidedIconLabel>
                                    <FaOilCan color={visitedSections[2]?colors.iconGreen:'grey'} size={32} style={{marginRight:10}}/>
                                    <SelectOption 
                                        fieldName="question_18"
                                        options={[
                                            {value: "--", label: "--"},
                                            {value: "Sim, e é Lubrax+", label: "Sim, e é Lubrax+"},
                                            {value: "Sim, mas não é Lubrax+", label: "Sim, mas não é Lubrax+"},
                                            {value: "Não", label: "Não"}
                                        ]}
                                    />
                                    </SidedIconLabel>
                                </RowOptions>
                            )}
                        />
                        <Question 
                            text={()=>( <> Quais dos serviços a seguir o posto possui/oferece aos clientes? </>)}
                            options={()=>(
                                <>
                                    <Checkbox fieldName="question_19" label="Lavagem automotiva"/>
                                    <Checkbox fieldName="question_20" label="Mecânica"/>
                                    <Checkbox fieldName="question_21" label="Borracharia"/>
                                    <Checkbox fieldName="question_22" label="Nenhum dos serviços anteriores"/>
                                    <Checkbox fieldName="question_23" label="Outros"/>
                                </>
                            )}
                        />
                                  
                        </LeftColumn>
                        <RightColumn>
                        <Question 
                            text={()=>( <> Marque os negócios (em operação) que estão acessíveis no terreno do posto </>)}
                            options={()=>(
                                <>
                                <RowOptions>
                                    <Checkbox fieldName="question_24" label="Não possui outros negócios no terreno"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_25" label="Restaurante/Churrascaria"/>
                                    <Checkbox fieldName="question_34" label="Salão de beleza"/>
                                    
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_26" label="Lanchonete/Bar/Padaria"/>
                                    <Checkbox fieldName="question_33" label="Pit Stop Skol"/>
                                </RowOptions>
                                
                                <RowOptions>
                                    <Checkbox fieldName="question_27" label="Fast Food"/>
                                    <Checkbox fieldName="question_32" label="Hotelaria"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_28" label="Auto-peças"/>
                                    <Checkbox fieldName="question_31" label="Farmácia"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_29" label="Lavanderia"/>
                                    <Checkbox fieldName="question_36" label="Sorveteria"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_30" label="Pet Shop"/>
                                    <Checkbox fieldName="question_37" label="Academia"/>
                                </RowOptions>
                                <RowOptions>
                                    
                                    <Checkbox fieldName="question_35" label="Loja de roupa"/>
                                    <Checkbox fieldName="question_38" label="Outros"/>
                                </RowOptions>
                                </>
                            )}
                        />

<Question 
                            text={()=>( <> Dentre as opções a seguir,
                                com quais delas o posto possui convênio firmado para abastecimento de carros </>)}
                            options={()=>(
                                <>
                                <RowOptions>
                                    <Checkbox fieldName="question_44" label="Não possui qualquer tipo de convênio para abastecimento"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_40" label="Locadora de carros (Exemplo: Localiza, Movida, Unidas, etc.)"/>
                                    <Checkbox fieldName="question_41" label="Transportadoras"/>
                                </RowOptions>
                                <RowOptions>
                                    <Checkbox fieldName="question_42" label="Outras empresas, que não são transportadoras"/>
                                    <Checkbox fieldName="question_43" label="Órgãos públicos"/>
                                </RowOptions>
                                
                                <RowOptions>                                    
                                    <Checkbox fieldName="question_45" label="Outros"/>
                                </RowOptions>                                
                                </>
                            )}
                        />

                        </RightColumn>                                                 
                    </FormColumns> 
                    </Waypoint>                 
                    </FadeIn>
                </FormContainer> 
                <FormSideBar>
                    <InternalHeaderLogo>
                    <ExternalLink href="https://bigdata.com.br/">
                        <LogoPoweredby>
                            <LogoDescription colorStyle='default'>Powered by</LogoDescription>
                            <Logo src={LogoBigDataDefault} />
                        </LogoPoweredby>
                        </ExternalLink>
                    </InternalHeaderLogo>
                   
                    
                    <BlockInfo>
                            <IconCircleContainer bgColor={visitedSections[0]?colors.mainGreen:'grey'} size={40}>
                                <FaGasPump color="white" size={20}/>
                            </IconCircleContainer>
                            <DescriptionBox>
                                Funcionamento e Estrutura
                            </DescriptionBox>
                    </BlockInfo>
                    <BlockInfo>
                        <IconCircleContainer bgColor={visitedSections[1]?colors.mainGreen:'grey'} size={40}>
                            <FaMapMarkerAlt color="white" size={20}/>
                        </IconCircleContainer>
                        <DescriptionBox>
                            Localização
                        </DescriptionBox>
                    </BlockInfo>
                    <BlockInfo>
                        <IconCircleContainer bgColor={visitedSections[2]?colors.mainGreen:'grey'} size={40}>
                            <FaHandHolding color="white" size={20}/>
                        </IconCircleContainer>
                        <DescriptionBox>
                            Ofertas e Serviços
                        </DescriptionBox>
                    </BlockInfo>

                    <ControlsContainer>
                        <InputControl 
                            type='button' 
                            value='Resetar' 
                            onClick={()=>resetForm(initialValues)}
                            textColor="white"
                            backgroundColor={colors.darkGray}
                            borderColor={colors.darkGray}
                            hoverBackground="#E3E3E3"
                            hoverTextColor={colors.mainGreen}
                        
                        />
                       <InputControl 
                            type='submit' 
                            value='Calcular'                             
                            textColor="white"
                            backgroundColor={colors.mainGreen}
                            borderColor={colors.mainGreen}
                            hoverBackground="#E3E3E3"
                            hoverTextColor={colors.mainGreen}                        
                        />
                    </ControlsContainer>
                </FormSideBar>

            </Form>
        )}
        </Formik>
        </FormContentWrapper>     
        </>
    );
}

export default FormContent;