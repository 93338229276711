import styled from 'styled-components';


export const Label = styled.label`
    margin-left: 10px;
    font-size: 18px;
    line-height: 25px;
`;

export const CheckContainer = styled.div`
    margin-bottom: 20px;    
     min-width:40%; 
    display: flex;
    align-items: flex-start;    
`;