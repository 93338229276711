import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LogoBigDataDefault from '../../images/logo_big_data.png';
import LogoBigDataWhite from '../../images/logo_big_data_branca.png';
import LogoVibraDefault from '../../images/logo_br.png';
import LogoVibraWhite from '../../images/logo_br_branca.png';
import LogoBr from '../../images/logo_br.png';

import { 
    HeaderSection,
    Logo,
    LogoPoweredby,
    LogoDescription,
    ExternalLink
} from './styles';

const Header = ({ 
    rightElementsStyle = 'default', 
    color= 'white', 
    showBrLogo = true, 
    showBigLogo = true,
    showBigLogoOnlyMobile = false,
    enableLink = true,
    clickAction = null,
    fixWhiteBackgroundOnMobile = false
}) => {

    const [redirectToHome, setRedirectToHome] = useState(false);

    let LogoBigData=null;
    const reqLogo = () =>{
        if (rightElementsStyle === 'white'){
            LogoBigData = LogoBigDataWhite;
            return;
        }
        LogoBigData = LogoBigDataDefault;
    }
    reqLogo();

    let LogoVibra=null;
    const reqLogoVibra = () =>{
        if (rightElementsStyle === 'white'){
            LogoVibra = LogoVibraWhite;
            return;
        }
        LogoVibra = LogoVibraDefault;
    }
    reqLogoVibra();

    if(enableLink && redirectToHome) {        
        return (<Redirect to={{
            pathname: "/",                     
        }} />)
    }

    const handleClick = () => {
        if(enableLink) {
            setRedirectToHome(true);
        } else if(clickAction) {
            clickAction();
        }
    };

    return (
        <HeaderSection color={color} fixWhiteBackgroundOnMobile={fixWhiteBackgroundOnMobile}>
            {showBrLogo && (<Logo enableLink onClick={handleClick} src={LogoVibra} alt={'texto'} />) }
            {showBigLogo && (<ExternalLink 
                showBigLogoOnlyMobile={showBigLogoOnlyMobile} 
                href="https://bigdata.com.br/">
                <LogoPoweredby>
                    <LogoDescription colorStyle={rightElementsStyle}>Powered by</LogoDescription>
                    <Logo src={LogoBigData} />
                </LogoPoweredby> 
            </ExternalLink>) }
        </HeaderSection>
        
    )
}



export default Header;