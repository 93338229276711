import styled from 'styled-components';

import {colors} from '../../constants/Colors';

export const ButtonStyled = styled.button`
    padding: 16px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;    
    border: none;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.borderColor || colors.mainGreen};
    width:  ${(props) => props.width || '100%'};
    color:  ${(props) => props.textColor || colors.mainGreen};
    background-color: ${(props) => props.backgroundColor || colors.white};
    z-index: 999;
    border-radius: 45px;

    :hover {
        background-color: ${props => props.hoverBackground || colors.darkGray};
        color: ${props => props.hoverTextColor || colors.white};
        border-color: ${(props) => props.hoverBorderColor || colors.mainGreen};
        border: 1px solid;
    }

`;

export const RightIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: -72px;
    margin-top: 18px;
    z-index: 999;
`;
