import React, { useState } from 'react';
import { colors } from '../../constants/Colors';

import { 
    ButtonStyled,
    ButtonContainer,
    SelectItem,
    MainContainer,
    ItemsContainer,
} from './styles';

import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const CollapsedButton = ({
    text = '',
    width = '100%',
    list=[],
    onPressFunction,
}) => {

    const [collapsed, setCollapsed] = useState(false); 
    const [hover, setHover] = useState(false);

    const setIcon = (color) =>{
        if (collapsed){
            return (                    
                    <FaChevronUp color={color} />
            )
        }
        return (                
                <FaChevronDown color={color} />
        )
    }

    const ChangeStateButton = () =>{
        setCollapsed(!collapsed);
    }

    const handleMouseEnter = () => {        
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
    }

    return (
        <MainContainer width= {width}>
            <ButtonContainer onClick={ChangeStateButton}  >
                <ButtonStyled
                        textColor={colors.white}
                        backgroundColor={colors.mainGreen}
                        borderColor ={colors.white}

                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {text}                        
                        {setIcon(hover ? colors.mainGreen : 'white')}                        
                </ButtonStyled>
            </ButtonContainer>
            <ItemsContainer>
                {(collapsed) ?
                list.map((item,index) =>{
                    let borderType = '';
                    if (index ===0) {borderType = 'top';}
                    else if (index === list.length-1 ){borderType = 'bottom';}
                    return (
                        <SelectItem key={item} roundedBorderType = {borderType} onClick={()=>onPressFunction(item)}>{item}</SelectItem>
                    )
                }) : null
                }
             </ItemsContainer>
        </MainContainer>
    )

}



export default CollapsedButton;