import React, { useState, useEffect, useContext } from 'react';
import FadeIn from 'react-fade-in';
import {Header, TextInput, Button, Alert} from '../../components/index';
import BombaImage from '../../images/bomba-1.PNG';

import { ThemeContext } from '../../App';

import { logout, login, saveLogin, saveUserAgent } from '../../api/services';
import { colors } from '../../constants/Colors';
import {
    Container, 
    ColumnsContainer, 
    Column, 
    LeftImage, 
    HelloText, 
    WelcomeText, 
    GetStartedText,
    ButtonsContainer, 
} from './styles';
import { Redirect } from 'react-router-dom';

import { WARNING } from '../../constants/Constants';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(null);

    const { setBodyColor } = useContext(ThemeContext);

    useEffect(() => {
        setBodyColor('white');  
        logout();       
    }, []);

    const handleAlert = (alertObj, duration) => { 
        setDisplayAlert(alertObj);
        setTimeout(() => {
            setDisplayAlert(null);            
          }, duration);
    };

    const tryLogin = async () =>{
        try{
            logout();
            const result = await login({username , password});
            if(result.status && result.auth_token) {
                saveUserAgent(window.__USER_AGENT__);
                saveLogin(result.auth_token);
                setRedirectToHome(true);                
            } else if(result.msg){
                handleAlert({
                    type: WARNING,
                    message: result.msg,
                    closeEnable:true
                }, 7000);                
            }                        
        }catch(e){
            handleAlert({
                type: WARNING,
                message: e.message,
                closeEnable:true
            }, 7000);
        }
    }

    if(redirectToHome) {
        return (<Redirect to={{
            pathname: "/",                      
          }} />)
    }

    return (
        <Container>
            {displayAlert ? 
                <Alert 
                    close={()=>setDisplayAlert(null)}
                    type={displayAlert.type} 
                    message={displayAlert.message}
                    closeEnable={displayAlert.closeEnable}  
                /> : null}
            <Header color="transparent"/>
            <ColumnsContainer>
                <Column>
                    <LeftImage src={BombaImage} alt={'texto'} />
                </Column>
                
                <Column >
                <FadeIn>
                
                    <HelloText>Olá!</HelloText>
                    <WelcomeText>Bem-vindo ao simulador de potencial de volume da Vibra Energia</WelcomeText>
                    <GetStartedText>Para começar, faça o login na sua conta:</GetStartedText>
                
                    <TextInput 
                        placeholder={'Nome de usuário'} 
                        value={username} 
                        setValue={setUsername}
                        placeholderTextColor = {colors.darkGray}
                    />
                    <TextInput 
                        inputType={'password'} 
                        placeholder={'Senha'} 
                        value={password} 
                        setValue={setPassword}
                        marginTop={'32px'}
                        placeholderTextColor = {colors.darkGray}
                    />
                    
                    <ButtonsContainer>
                        <Button 
                            text={'Avançar'} 
                            colorScheme={'green-background-white-text'} 
                            width={'38%'} 
                            onPress={tryLogin}
                            />
                    </ButtonsContainer>
                   
                    </FadeIn>
                </Column>
                
            </ColumnsContainer>
        </Container>
    )
}

export default Login;