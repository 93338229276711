import styled, { keyframes } from 'styled-components';
import { colors } from '../../constants/Colors';


const FadeIn = (fadeOut) => keyframes`
    ${fadeOut ? 
        `0% { opacity: 0; }
        20% { opacity: 1; }
        85% { opacity: 1; }
        100% { opacity: 0; }`
        
    : `0% { opacity: 0; }
        25% { opacity: 1; }
        75% { opacity: 1; }
        100% { opacity: 0; }`}
`;

export const AlertContainer = styled.div`
    padding: 20px;
    background-color: ${props=>props.bgColor || colors.INFO };
    color: white;
    /* margin-bottom: 15px; */
    position: fixed;
    top: 10px;
    right: 10px;
    /* margin: 5% auto; */
    /* top: 20px; */
    /* align-self: center; */
    /* opacity: 1; */
    z-index:20000;
    animation: ${props => FadeIn(props.fadeOut)};
    animation-duration: ${props=>props.duration || '3'}s;
    animation-fill-mode: forwards;   
`;

export const CloseIcon = styled.span`
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;

    :hover {
        color: black;
    }
`;