import React from 'react';
import {
    Field
} from 'formik';
import { 
    Select
 } from './styles';
 import {
    ErrorContainer,
    InputContainer
} from '../../globalStyles';

export const SelectOption = ({fieldName, label, options}) => {

    const MySelect = ({
        field,
        form: { touched, errors },
        ...props
    }) => (
        <InputContainer>
            <Select {...field}>
                {options.map(option => (
                    <option key={option.label} value={option.value}>{option.label}</option>
                ))}
            </Select>
            
            {touched[field.name] &&
                errors[field.name] && <ErrorContainer>{errors[field.name]}</ErrorContainer>}
            
            
        </InputContainer>
    )

    return (
        <>
        <label htmlFor={fieldName}>{label}</label>            
            <Field name={fieldName} component={MySelect} />                            
        </>
    )
}

export default SelectOption;