import React,{ useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
    FaGasPump,
    FaMapMarkerAlt,
    FaHandHolding,    
    FaCheck
} from 'react-icons/fa';
import { cnpjMask, onlyNumbers } from '../../../utils/functions/string';

import { RollingNumbers, Alert } from '../../../components';

import { colors } from '../../../constants/Colors';

import { 
    TitleCNPJ,
    } 
from '../../Form/styles';

import {
    ControlsContainer,
    InputControl,
    DescriptionBox
} from '../FormContent/styles';

import {
    IconCircleContainer
} from '../../Form/FormContent/styles';
import { 
    ResultContainer,  
    ResultColumnsContainer, 
    ResultColumn,
    ContentRow,
    Value,
    SmallTitle,
    ContentRowColumn,
    ResultWindowContainer,
    Title,
    SavingContainer,
    NameInput,
    SaveButton,
    FormHeader, 
    FormTitle, 
    ValueRow,
    ValueUnity,
    SectionsBlockContainer,
    ResultColumnBreakPoint,
    ContentRowBreakpoint
} from './styles';

import { saveSimulation } from '../../../api/services';
import { SUCCESS, ERROR } from '../../../constants/Constants';

export default function ResultWindow({resultData, formValues, company_name, closeResultWindow }) {

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [formName, setFormName] = useState('');
    const [savedStatus, setsavedStatus] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(null);

    const handleAlert = (alertObj, duration) => { 
        setDisplayAlert(alertObj);
        setTimeout(() => {
            setDisplayAlert(null);            
          }, duration);
    };
    const handleNameChange = (event) => {
        setFormName(event.target.value);
    };

    const saveForm = async () => {
        if(!formName) { return };

        const formBody = {
            ...formValues,
            question_07: formValues.question_07 === 'Sim' ? true : false,
            question_08: formValues.question_08 === 'Sim' ? true : false,
            question_09: formValues.question_09 === 'Sim' ? true : false,
            question_10: formValues.question_10 === 'Sim' ? true : false,
            question_11: formValues.question_11 === 'Sim' ? true : false,
            question_17: formValues.question_17 === 'Sim' ? true : false,
            ...resultData,            
            simulation_name: formName,
        }        
        try {
            const result = await saveSimulation(formBody);
            if(result) {
                setsavedStatus(true);
                setDisplayAlert({
                    type:SUCCESS, 
                    message:'Simulação salva com sucesso.',
                    fadeOut: true,
                    duration: 4
                });
            }                                  
        } catch (e) {
            handleAlert({
                type: ERROR,
                message: e.message,
                closeEnable:true
            }, 7000);
        }
    }

    if(redirectToForm) {
        return (<Redirect to={{
            pathname: "/",          
          }} />)
    }

    return (
        <ResultWindowContainer>
        {displayAlert ? 
            <Alert 
                close={()=>setDisplayAlert(null)}
                type={displayAlert.type} 
                message={displayAlert.message}
                fadeOut={displayAlert.fadeOut}
                duration={displayAlert.duration} 
        /> : null}          
        <ResultContainer>
            <FormHeader>
                <TitleCNPJ>{cnpjMask(onlyNumbers(formValues.cnpj))}</TitleCNPJ>
                <FormTitle>{company_name}</FormTitle>
            </FormHeader>
            <ResultColumnsContainer>
                <ResultColumnBreakPoint width={'300px'}>

                <SectionsBlockContainer>
                    <ContentRow>
                        <IconCircleContainer bgColor={colors.mainGreen} size={50}>
                            <FaGasPump color="white" size={30}/>
                        </IconCircleContainer>
                        <DescriptionBox>
                            Funcionamento e Estrutura
                        </DescriptionBox>
                    </ContentRow>
                    <ContentRow>
                    <IconCircleContainer bgColor={colors.mainGreen} size={50}>
                            <FaMapMarkerAlt color="white" size={30}/>
                        </IconCircleContainer>
                        <DescriptionBox>
                            Localização
                        </DescriptionBox>
                    </ContentRow>
                    <ContentRow>
                        <IconCircleContainer bgColor={colors.mainGreen} size={50}>
                            <FaHandHolding color="white" size={30}/>
                        </IconCircleContainer>
                        <DescriptionBox>
                            Ofertas e Serviços
                        </DescriptionBox>
                    </ContentRow>
                </SectionsBlockContainer>
                    <ContentRow align='flex-start'>
                        <ControlsContainer width="350px">
                        <InputControl 
                            type='button' 
                            value='Voltar' 
                            onClick={closeResultWindow}
                            textColor={colors.darkGray}
                            backgroundColor="white"
                            borderColor={colors.darkGray} 
                            height="50px"
                        />
                        <InputControl 
                            type='button' 
                            value='Voltar para a tela inicial' 
                            onClick={()=>setRedirectToForm(true)}
                            textColor={colors.darkGray}
                            backgroundColor="white"
                            borderColor={colors.darkGray}
                            hoverTextColor={colors.mainGreen}   
                            height="50px"                                                 
                        />
                        </ControlsContainer>
                    </ContentRow>
                </ResultColumnBreakPoint>

                <ResultColumn width={'250px'} >                    
                    <ContentRowColumn>
                    <Title>Potencial de volume:</Title>
                        <SmallTitle>Gasolina</SmallTitle>
                        <ValueRow>
                            <Value color="#FDD152">                                
                                <RollingNumbers number={resultData.gasolina} />
                            </Value>
                            <ValueUnity>{' '}m<sup>3</sup>/mês</ValueUnity>
                        </ValueRow>
                    </ContentRowColumn>
                    <ContentRowColumn>
                        <SmallTitle>Etanol</SmallTitle>
                        <ValueRow>
                            <Value color="#14B352">                                
                                <RollingNumbers number={resultData.etanol} />
                            </Value>
                            <ValueUnity>{' '}m<sup>3</sup>/mês</ValueUnity>
                        </ValueRow>
                    </ContentRowColumn>
                    <ContentRowColumn>
                        <SmallTitle>Diesel</SmallTitle>
                        <ValueRow>
                            <Value color="#006298">                                
                                <RollingNumbers number={resultData.diesel} />
                            </Value>
                            <ValueUnity>{' '}m<sup>3</sup>/mês</ValueUnity>
                        </ValueRow>
                    </ContentRowColumn>
                    <ContentRowColumn>
                        <SavingContainer>
                            <NameInput 
                                value={formName} 
                                onChange={handleNameChange} 
                                placeholder="Nome da Simulação" 
                                type="text">
                            </NameInput>                            
                            <SaveButton type="button" value="Salvar"                               
                                onClick={saveForm}
                                disabled={savedStatus}
                                backgroundColor={savedStatus ? '#E3E3E3' : colors.mainGreen}
                                color={savedStatus ? colors.mainGreen : colors.white}                                                               
                            >
                                {savedStatus ? 'Salvo' : 'Salvar'}
                                {savedStatus ? <FaCheck style={{marginLeft: 10}}/> : null}
                            </SaveButton>

                        </SavingContainer>
                    </ContentRowColumn>  

                    <ContentRowBreakpoint align='flex-start'>
                        <ControlsContainer >
                        <InputControl 
                            type='button' 
                            value='Voltar' 
                            onClick={closeResultWindow}
                            textColor={colors.darkGray}
                            backgroundColor="white"
                            borderColor={colors.darkGray} 
                            height="50px"
                        />
                        <InputControl 
                            type='button' 
                            value='Voltar para a tela inicial' 
                            onClick={()=>setRedirectToForm(true)}
                            textColor={colors.darkGray}
                            backgroundColor="white"
                            borderColor={colors.darkGray}
                            hoverTextColor={colors.mainGreen}   
                            height="50px"                                                 
                        />
                        </ControlsContainer>
                    </ContentRowBreakpoint>

                </ResultColumn>
            </ResultColumnsContainer>
    
        </ResultContainer>
        </ResultWindowContainer>
    )
}
