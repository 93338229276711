import styled, { keyframes } from 'styled-components';
import globalStyled from '../../globalStyles';

import {colors} from '../../constants/Colors';

export const FadeIn = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

const SlideIn = keyframes`
    0% {top: 50%; }
    100% { 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;         
    }
`;

export const Body = globalStyled.body`
    background-color: ${colors.mainGreen};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.color ? props.color : colors.mainGreen};    
    width: 100%;
    height: 100%;          
    min-width: 500px;
    ${props=>props.paddingBottom?`padding-bottom : ${props.paddingBottom}` : '' }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    padding-top: 32px;
    padding-bottom: 15px;
    justify-content: ${props => props.justifyContent || 'space-between'};
    background-color: ${props=> props.background || colors.mainGreen};    
    width: 100%;
`;

export const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.color ? props.color : colors.mainGreen};
    width: 100%;
    flex-wrap: wrap;
    padding-top: 30px;
    
    /* padding-bottom: 100px; */

    /* @media screen and (max-width: 990px) {
        flex-direction: column;
        flex-wrap: nowrap;
        padding-bottom: 10px;
    } */
`;

export const Column = styled.div`
    display: flex;
    justify-content: space-between;
    flex:1;
    flex-direction: column;
    background-color: ${props => props.color ? props.color : colors.mainGreen};
    padding: 0px 32px;
    min-width: 500px;
    height: 500px;
    position: relative;
    margin-top: 100px;     
`; 

export const ColumnAddress = styled(Column)`
    overflow:auto;
    width: 100%;
    min-width: 50%;    
`;

export const FadeInTransparency = styled.div`
    height: 100vh;
    width: 50vw;
    position: absolute;    
    z-index: 1500;
    background-image: linear-gradient(to right, rgba(255,255,255,0),
    rgba(255,255,255,0.05), 
    rgba(255,255,255,.05), 
    rgba(255,255,255,1));

    @media screen and (max-width: 990px) {
        display: none;
    }
`;

export const ColumnImage = styled(Column)`
     @media screen and (max-width: 990px) {
        width: 300px;
        height: 300px;
        margin-top: 200px;        
    }
`;

export const ColumnForm = styled(Column)`    
    width: 100%;
    @media screen and (max-width: 990px) {
        margin-top: 0px;
        height: 100%;
        min-width: 100%;
        width: 100%;
    }
`;

export const LeftImage = styled.img`        
    width: 80%;
    max-width: 500px;
    height: auto;    
    position: absolute;
    bottom: 0;
    right: 0;
`;


export const DownloadImage = styled.img`
    width: 16px;
    height: 16px;
`;


export const ImageTextContainer = styled.div`
    background-color: ${colors.mainGreen};  
    margin-top: 32px; 
    justify-content: center;
    align-items: flex-start;
`;


export const DownloadText = styled.span`
    font-size: 16px;
    font-weight: bold;

    text-decoration: underline;
    margin-left: 8px;
    color: ${colors.white};
`;

export const HelloText = styled.span`
    font-size: 90px;
    font-weight: bold;
    color: ${colors.black};
`;

export const WelcomeText = styled.p`
    font-size: 32px;
    font-weight: bold;
    color: ${props => props.color ? props.color : colors.black};
    margin-right: 16px;
`;


export const GetStartedText = styled.p`
    margin-top: 30px;
    font-size: 20px;
    font-weight: medium;
    color: ${colors.black};
    margin-right: 16px;
    margin-bottom: 24px;    
`;

export const PostoTitle = styled.h1`
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    color: ${colors.mainGreen};

`;

export const AddressContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'column'};
    width: 80%;
`;

export const AddressTitle = styled.span`
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 20px;
`;

export const HoverIcon = styled.div`
    :hover {
        cursor: pointer;
    }
`;

export const AddressLine = styled.p`
    line-height: 25px;
    ${props => props.upper ? 'text-transform: uppercase' : ''};
`;

export const AddressInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: ${props=> props.width || '100%'};
    margin: 10px;
`;
 export const AddressLabel = styled.label`
    color: #A5A5A5;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 8px;
 `;

 export const AddressInput = styled.input`
    border: 1px solid #E3E3E3;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    line-height: 25px;
    padding: 8px;   
    width: ${props=> props.width || '100%'};
 `;

 export const Select = styled.select`
    border: 1px solid #E3E3E3;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    line-height: 25px;
    padding: 10px;
 `;

 export const FormAddress = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 `;

export const ButtonDownload = styled.button`
    background-color: ${colors.mainGreen};  
    flex: 0 1 50%;
    align-self: flex-start;
    margin-top: 32px; 
    justify-content: center;
    align-items: flex-start;
    border: none;
    cursor: pointer;
    outline: none;    
`;

export const MapPageContainer = styled.div`
    display: flex;
    flex-direction: row;    
    width: 100%;
    position: relative;

    @media screen and (max-width: 990px) {
        flex-direction: column;               
    }
`;