import styled, {keyframes} from 'styled-components';

const RollingEffect = (finalPosition) => keyframes`
    100% {
        margin-top: -${finalPosition}em;
    }
`;


const Digits = styled.ul`
    float:left;
    list-style-type: none;
    font-size: 1em;
    line-height: 1em;

    animation-duration: 1.6s;
    animation-timing-function: ease;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    
`;

export const Digit = styled(Digits)`
    margin-top: 0em;
    animation-name: ${props => RollingEffect(props.digit)};
`;

export const Counter = styled.div`
    height: 1em;
    overflow: hidden;    
`;

