import React from 'react';
import {
    Field, ErrorMessage
} from 'formik';
import {      
    RadioOptionsContainer,
    OptionContainer
 } from './styles';
import {
    ErrorContainer,
    InputContainer
} from '../../globalStyles';


export const RadioGroup = ({fieldName, options}) => {
    return (
        <InputContainer>
        <RadioOptionsContainer role="group">
            {options.map(option => (                
                <OptionContainer key={option.label}>
                    <label style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Field style={{width: 20, height: 20 }} type="radio" name={fieldName} value={option.value} />
                        <span style={{height: 20, width:50,  marginLeft: 15}}>{option.label}</span>
                    </label>
                </OptionContainer>                                        
            ))}
            
        </RadioOptionsContainer>
        <ErrorContainer>
                <ErrorMessage name={fieldName}/>
            </ErrorContainer>
        </InputContainer>
        
    )
}

export default RadioGroup;