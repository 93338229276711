import React, { useState, useEffect, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from '../../globalStyles';
import { Header, Alert } from '../../components'
import FormContent from './FormContent';
import { initialValues, validationSchema } from  './formConfig';
import { getDefaultValues } from '../../api/services';
import { formatBodyInitialValues, formatBodyAddressData, formatBodyDefaultValues} from '../../utils/functions/createBody';
import { removeFormat, cnpjMask, onlyNumbers } from '../../utils/functions/string';
import { ThemeContext } from '../../App';
import { ERROR } from '../../constants/Constants';
import {
    FormHeader,
    TitleCNPJ,
    FormTitle,
    FormPage,
    FormContainer,
    HeaderContainer, 
} from './styles';
import FadeIn from 'react-fade-in';

const Form = ({location}) => {
    const { formValues, cnpj, currentAddress } = location;
    const [ defaultValues, setDefaultValues ] = useState(null);
    const { setBodyColor } = useContext(ThemeContext);
    const [displayAlert, setDisplayAlert] = useState(null);


    const handleAlert = (alertObj, duration) => { 
        setDisplayAlert(alertObj);
        setTimeout(() => {
            setDisplayAlert(null);            
          }, duration);
    };

    const getValues = async () =>{
        if(cnpj) {
            try {
                const result = await getDefaultValues({cnpj:removeFormat(cnpj)});                
                setDefaultValues(formatBodyDefaultValues(result));
            }catch(e){                
                handleAlert({
                    type: ERROR,
                    message: e.message,
                    closeEnable:true
                }, 7000);
            }      
        }              
    }

    const formProps = () => {
        const propsReturn = {};
        if(formValues) {
            propsReturn.initialValues = formatBodyInitialValues(formValues);
            propsReturn.currentAddress = formatBodyAddressData(formValues);
        } else if(cnpj && defaultValues) {
            propsReturn.initialValues = formatBodyInitialValues({
                ...initialValues, 
                cnpj:removeFormat(cnpj), 
                ...defaultValues 
            });
        } else {
            propsReturn.initialValues = null;
        }
        if(currentAddress) {
            propsReturn.company_name = currentAddress.company_name;
        }
        return propsReturn;
    }

    useEffect(() => {        
        if(!formValues) {            
            getValues();            
        }
        setBodyColor('white');
    }, []);

    
    const formContentProps = formProps();
    const stringCnpj = cnpj ? cnpj : formValues ? formValues.cnpj : null;
    const stringNome = currentAddress ? currentAddress.company_name : formValues ? formValues.company_name : null;

    if(!stringCnpj || !stringNome) {
        return (<Redirect to={{
            pathname: "/",          
          }} />)
    }

    return (
        <>
            <Header showBigLogoOnlyMobile={true}/>
            <Container>
            {displayAlert ? 
                <Alert 
                    close={()=>setDisplayAlert(null)}
                    type={displayAlert.type} 
                    message={displayAlert.message}
                    closeEnable={displayAlert.closeEnable}  
                /> : null}              
            <FormPage>
                <HeaderContainer>
                    <FadeIn>
                    <FormHeader>
                        <TitleCNPJ>{cnpjMask(onlyNumbers(stringCnpj))}</TitleCNPJ>
                        <FormTitle>{stringNome}</FormTitle>
                    </FormHeader>
                    </FadeIn>                
                </HeaderContainer>
                <FormContainer>
                    {formContentProps.initialValues ? (
                        <FormContent 
                        {...formContentProps}
                        validationSchema={validationSchema}
                        company_name={stringNome}                        
                    />
                    ) 
                    : null }                                         
                </FormContainer>
                </FormPage>
            </Container>            
        </>
    )
}

export default Form;