import styled from 'styled-components';

const Container = styled.div`
  background-color: pink;
  height: 100%;
  position: relative;
  height: 500px;
`;

export default Container;
