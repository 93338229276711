import React from 'react';
import {
    Field, ErrorMessage
} from 'formik';
import { CheckContainer, Label } from './styles';

export const Checkbox = ({fieldName, label}) => {
    return (
        <>
            <CheckContainer>                
                <Field name={fieldName} type="checkbox" 
                    style={{
                        minWidth: 20, 
                        minHeight:20,
                    }}/>
                <Label htmlFor={fieldName}>{label}</Label>
                    
                <ErrorMessage name={fieldName} />
            </CheckContainer>            
        </>
    )
}

export default Checkbox;
