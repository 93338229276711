import React from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { RollingNumbers } from '../../components';

import  Container from './styles'

const Address = () => (
  <RollingNumbers />
  // <Container>
  //   <h1>Address</h1>
  //   <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
  //     <TileLayer
  //       attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //     />
  //     <Marker position={[51.505, -0.09]}>
  //       <Popup>
  //         A pretty CSS3 popup. <br /> Easily customizable.
  //       </Popup>
  //     </Marker>
  //   </MapContainer>

  


  // </Container>
);

export default Address;
