import styled from 'styled-components';

import {colors} from '../../constants/Colors';

export const ButtonStyled = styled.button`
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    border: none;
    cursor:pointer;
    outline: none;
    border: 1px solid;
    border-color: ${(props) => props.borderColor || colors.mainGreen};
    color:  ${(props) => props.textColor || colors.mainGreen};
    background-color: ${(props) => props.backgroundColor || colors.white};
    width: 100%;
    z-index: 999;
    border-radius: 45px;

    display: flex;
    padding-right: 15px;
    padding-left: 20px;
    justify-content: space-between; 
    align-items: center;
    flex-direction: row;

    line-height: 60px;
    font-size: 20px;
    font-weight: bold;

    :hover {
        background-color: white;  
        color: ${colors.mainGreen}      
    }
`;

export const ButtonContainer = styled.div`
    height: 60px;
    width:  '100%';
`;

export const MainContainer = styled.div`
    height: 60px;
    width:  ${(props) => props.width || '100%'};
    flex-direction: column;
`;

export const ButtonArea = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between; 
    flex-direction: row;
`;

export const RightIcon = styled.img`
    width: 36px;
    height: 36px;
    z-index: 999;
    margin-top: 10px;
    align-self: end;
    margin-right: 10px;
`;

export const TextButton = styled.span`
    line-height: 60px;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.white};
    margin-left: 10px;

    :hover {
        color: ${colors.mainGreen}
    }
`;


export const SelectItem = styled.span`
    font-size: 16px;
    color: ${colors.black};
    z-index: 999;
    padding: 6px;
    opacity: 0.8;
    padding: 16px 32px;
    background-color: ${colors.white};
    cursor: pointer;
    :hover {
        opacity: 1;
    }
    border-radius: ${(props) => props.roundedBorderType==='top' ? '36px 36px' : '0px 0px'} ${(props) => props.roundedBorderType==='bottom' ? '36px 36px' : '0px 0px'};
`;

export const ItemsContainer = styled.div`
    margin-top: 8px;
    display:flex;
    flex-direction: column;
    flex:1;
    z-index: 999;
    max-height:160px;
    border-radius: 36px 36px 36px 36px;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar { 
        width: 0 !important;
        display: none; 
    }
`;
